import fp from 'lodash/fp';

import { convertPathUsingParams } from '@rfb/common';

import routingConfig from 'configs/routing';
import { clientLineStatusOptions } from '../../configs/status';
import {
  ClientLineTypes,
  clientLineCommissionTypeTranslations,
  clientLineTypeTranslations,
  clientLineTypeTranslationsFull,
} from '../../configs/type';

export const getTransformedNumberForView = (number: string, type: string): string =>
  `${ClientLineTypes.CC !== type ? number + '-' : ''}${clientLineTypeTranslations[type]}`;

export const getTransformedNumberForViewDCorDF = (agreement_number: string): string =>
  `${agreement_number ? agreement_number : 'Б/H'}`;

export const getTransformedStatus = (status: string): string => clientLineStatusOptions[status];

export const getTransformedStatusFull = (status: string): string =>
  clientLineTypeTranslationsFull[status];

export const getTransformedTableRow = (cellList: string[]) => {
  console.log(`clientLine/TableShort/getTransformedTableRow BEGIN: cellList = `, cellList);
  const [number, status, type, multi_brand, agreement_number, ...rest] = cellList;
  console.log(
    `clientLine/TableShort/getTransformedTableRow: agreement_number = `,
    agreement_number
  );
  const link: string = convertPathUsingParams(routingConfig.clientLinesDetailById.path, {
    id: number,
    type,
    multiBrand: multi_brand ? 1 : 0,
  });
  const numberForView: string =
    type === ClientLineTypes.DC || type === ClientLineTypes.DF
      ? getTransformedNumberForViewDCorDF(agreement_number)
      : getTransformedNumberForView(number, type);
  console.log(`clientLine/TableShort/getTransformedTableRow: numberForView = `, numberForView);
  const statusForView: string = getTransformedStatus(status);
  const result = { config: { link }, data: [numberForView, statusForView, ...rest] };
  console.log(`clientLine/TableShort/getTransformedTableRow END: result = `, result);
  return result;
};

export const getTransformedPercentRate = (value: string): string =>
  fp.pipe(
    fp.split(';'),
    fp.map((p) => fp.toNumber(p).toFixed(2)),
    fp.join('/')
  )(value);

export const getTransformedCommissionTypeList = (value: string): string[] => {
  const commissionTypeList: string[] = fp.split(';', value);
  return fp.map(
    (type: string): string => fp.path(type, clientLineCommissionTypeTranslations),
    commissionTypeList
  );
};
